import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  addUsers,
  updateUsers,
  onErrorStopLoad,
  getUserInfo,
} from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useWebSelector } from "../../redux/selector/web";
import { blockInvalidChar } from "../../utilities/helper";
import * as Images from "../../utilities/images";

const ViewUserInfo = ({
  userId,
}) => {
  console.log(userId, "userId");

  const dispatch = useDispatch();
  const toastId = useRef(null);
  const webData = useWebSelector();
  const { loading } = webData;

  const [getUserData, setGetUserData] = useState("");

  console.log(getUserData, "getUserData");

    // Function to format date as DD/MM/YYYY
    const formatDate = (dob) => {
      const date = new Date(dob);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

  //stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // add users
  const handleUsersInfo = () => {
    let params = {
      user_id: userId,
    };
    dispatch(
      getUserInfo({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setGetUserData(res?.data?.data)
          }
        },
      })
    );
  };
  useEffect(() => {
    handleUsersInfo()
  }, [])
  return (
    <>
      <div className="create-user-outer">

        <div className="row">
          <div className="col-5">
            <div className="text-center">
              <img className="userViewImg" src={getUserData?.profile_pic ||Images.DummyImage} />
            </div>
          </div>
          <div className="col-7">
          <div className="mb-2 text-filed-outer">
            <label className="modal-labels">Full Name</label>
            <p className="mb-0 fontSize16 fw500">{getUserData?.name || "-----"}</p>

          </div>

          <div className="mb-2 text-filed-outer">
            <label className="modal-labels">Date of birth</label>
            <p className="mb-0 fontSize16 fw500">{getUserData.dob ? formatDate(getUserData.dob) : "-----"}</p>

          </div>

          <div className="mb-2 text-filed-outer">
            <label className="modal-labels">Phone No.</label>
            <p className="mb-0 fontSize16 fw500">{getUserData?.phone_number}</p>
          </div>


          <div className="mb-2 text-filed-outer">
            <label className="modal-labels">Status</label>
            <p className="mb-0 fontSize16 fw500">{getUserData?.is_admin_verified === 0
              ? "Pending"
              : getUserData?.is_admin_verified === 1
              ? "Accept"
              : getUserData?.is_admin_verified === 2
              ? "Reject"
              : ""}</p>
          </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default ViewUserInfo;
