const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "api/v1/admin/authenticate",
    FORGOT_PASSWORD: "/api/v1/admin/forget-password",
    RESET_PASSWORD: "api/v1/admin/reset-password",
    LOGOUT_ADMIN: "api/v1/admin/admin-logout",
  },
  webApiPath: {
    DASHBOARD_DATA: "/users/dashboard",
    GET_ALL_USER: "/api/v1/admin/get-all-users",
    GET_USER_INFO: "/api/v1/admin/get-user-by-id?user_id",
    ADD_USER: "/users",
    UPDATE_USER: "/users/update-user",
    UPDATE_USER_STATUS:"/api/v1/admin/verify-user",
    ACTIVE_INACTIVE_USER:"/api/v1/admin/activate-deactivate-user"
  },
};

export default ApiPath;
